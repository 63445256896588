export class ApiUrls {
    public static LOGIN_URL: string = '/api/auth/login';
    public static REGISTER_URL: string = '/api/register';

    //issuer state
    public static FETCH_ISSUER_STATES: string = '/api/issuerState';
    public static ADD_ISSUER_STATE: string = '/api/issuerState';
    public static UPDATE_ISSUER_STATE: string = '/api/issuerState';
    public static DELETE_ISSUER_STATE: string = '/api/issuerState';
    public static GET_ISSUER_STATE: string = '/api/issuerState';

    //carrier
    public static FETCH_CARRIERS: string = '/api/carrier';
    public static ADD_CARRIER: string = '/api/carrier';
    public static GET_CARRIER: string = '/api/carrier';
    public static UPDATE_CARRIER: string = '/api/carrier';
    public static DELETE_CARRIER: string = '/api/carrier';
    public static FILTER_CARRIERS: string = '/api/carrier/filter';
    public static GET_TIMEZONES: string = '/api/carrier/time-zones';
    public static GET_CARRIER_TIMEZONE: string = '/api/carrier/time-zone'

    //providers
    public static FETCH_PROVIDER: string = '/api/provider';
    public static ADD_PROVIDER: string = '/api/provider';
    public static GET_PROVIDER: string = '/api/provider';
    public static UPDATE_PROVIDER: string = '/api/provider';
    public static DELETE_PROVIDER: string = '/api/provider';

    //hos rules
    public static FETCH_HOSRULES: string = '/api/hOSRole';
    public static ADD_HOSRULE: string = '/api/hOSRole';
    public static GET_HOSRULE: string = '/api/hOSRole';
    public static UPDATE_HOSRULE: string = '/api/hOSRole';
    public static DELETE_HOSRULE: string = '/api/hOSRole';

    //restart
    public static FETCH_RESTARTS: string = '/api/restart';
    public static ADD_RESTART: string = '/api/restart';
    public static GET_RESTART: string = '/api/restart';
    public static UPDATE_RESTART: string = '/api/restart';
    public static DELETE_RESTART: string = '/api/restart';

    //rest-break
    public static FETCH_RESTBREAKS: string = '/api/restBreak';
    public static ADD_RESTBREAK: string = '/api/restBreak';
    public static GET_RESTBREAK: string = '/api/restBreak';
    public static UPDATE_RESTBREAK: string = '/api/restBreak';
    public static DELETE_RESTBREAK: string = '/api/restBreak';

    //cargo-type
    public static FETCH_CARGOTYPES: string = '/api/cargoType';
    public static ADD_CARGOTYPE: string = '/api/cargoType';
    public static GET_CARGOTYPE: string = '/api/cargoType';
    public static UPDATE_CARGOTYPE: string = '/api/cargoType';
    public static DELETE_CARGOTYPE: string = '/api/cargoType';

    //vehicle-fuel
    public static FETCH_VEHICLE_FUELS: string = '/api/vehicleFuel';
    public static ADD_VEHICLE_FUEL: string = '/api/vehicleFuel';
    public static GET_VEHICLE_FUEL: string = '/api/vehicleFuel';
    public static UPDATE_VEHICLE_FUEL: string = '/api/vehicleFuel';
    public static DELETE_VEHICLE_FUEL: string = '/api/vehicleFuel';

    //eld connection interface
    public static FETCH_ELD_CONNECTION_INTERFACES: string = '/api/eldConnectionInterface';
    public static ADD_ELD_CONNECTION_INTERFACE: string = '/api/eldConnectionInterface';
    public static GET_ELD_CONNECTION_INTERFACE: string = '/api/eldConnectionInterface';
    public static UPDATE_ELD_CONNECTION_INTERFACE: string = '/api/eldConnectionInterface';
    public static DELETE_ELD_CONNECTION_INTERFACE: string = '/api/eldConnectionInterface';

    //vehicle
    public static GET_VEHICLES: string = '/api/vehicle/filter';
    public static FETCH_VEHICLES: string = '/api/vehicle';
    public static ADD_VEHICLE: string = '/api/vehicle';
    public static GET_VEHICLE: string = '/api/vehicle';
    public static UPDATE_VEHICLE: string = '/api/vehicle';
    public static DELETE_VEHICLE: string = '/api/vehicle';

    //driver
    public static GET_DRIVERS: string = '/api/driver/filter';
    public static FETCH_DRIVERS: string = '/api/driver';
    public static ADD_DRIVER: string = '/api/driver';
    public static GET_DRIVER: string = '/api/driver';
    public static UPDATE_DRIVER: string = '/api/driver';
    public static DELETE_DRIVER: string = '/api/driver';
    public static GET_CO_DRIVERS: string = 'api/driver/co-drivers';
    public static GET_DRIVER_DEVICE_INFO: string = '/api/driver/device-info';
    public static RESET_PASSWORD: string = '/api/password-reset/change';

    //user
    public static GET_USERS: string = '/api/user/filter';
    public static FETCH_USERS: string = '/api/user';
    public static ADD_USER: string = '/api/user';
    public static GET_USER: string = '/api/user';
    public static UPDATE_USER: string = '/api/user';
    public static DELETE_USER: string = '/api/user';

    //permission
    public static FETCH_PERMISSIONS: string = 'api/permission';
    public static ADD_PERMISSION: string = '/api/permission';
    public static GET_PERMISSION: string = '/api/permission';
    public static UPDATE_PERMISSION: string = '/api/permission';
    public static DELETE_PERMISSION: string = '/api/permission';

    //role
    public static FETCH_ROLES: string = 'api/role';
    public static ADD_ROLE: string = '/api/role';
    public static GET_ROLE: string = '/api/role';
    public static UPDATE_ROLE: string = '/api/role';
    public static DELETE_ROLE: string = '/api/role';

    //eld event
    public static GET_HOS_GRAPH: string = '/api/client/graph/hos';
    public static GET_CURRENT_STATE: string = '/api/client/driver-state/current-state';
    public static GET_CURRENT_STATE_BY_DATE: string = '/api/client/driver-state/current-state';
    public static GET_DRIVER_STATES: string = '/api/client/driver-state/drivers-state';
    public static GET_DRIVER_DAILY_EVENTS: string = '/api/client/driver-state/daily-events';
    public static GET_DRIVER_DAILY_EVENTS_GROUP_BY_DATE: string = '/api/client/driver-state/daily-events-group-by-date';
    public static FILTER_UNIDENTIFIED_ELD_EVENTS: string = 'api/eld-event/unidentified/filter';
    public static ACCEPT_ELD_EVENT: string = '/api/eld-event/accept';
    public static REJECT_ELD_EVENT: string = '/api/eld-event/reject';

    //driver info
    public static GET_DRIVER_INFO: string = '/api/driver/details';
    public static GET_DRIVER_PROFILE: string = '/api/driver/profile';
    public static GET_DRIVER_TIME_ZONE_INFO = '/api/driver/time-zone-info';

    //eld info
    public static GET_ELD_INFO: string = '/api/eldInfo';
    public static DELETE_ELD_INFO: string = '/api/eldInfo';
    public static FILTER_ELD_INFOS = '/api/eldInfo/filter';

    //DVIR Status
    public static FETCH_DVIR_STATUSES: string = '/api/dVIRStatus';
    public static GET_DVIR_STATUS: string = '/api/dVIRStatus';
    public static ADD_DVIR_STATUS: string = '/api/dVIRStatus';
    public static UPDATE_DVIR_STATUS: string = '/api/dVIRStatus';
    public static DELETE_DVIR_STATUS: string = '/api/dVIRStatus';

    //DVIR
    public static FETCH_DVIRS: string = '/api/dVIR';
    public static FILTER_DVIRS = '/api/dVIR/filter';
    public static GET_DVIR: string = '/api/dVIR';
    public static DELETE_DVIR: string = '/api/dVIR';

    //Maintenance Service Type
    public static FETCH_MAINTENANCE_SERVICE_TYPES: string = '/api/maintenanceServiceType';
    public static ADD_MAINTENANCE_SERVICE_TYPE: string = '/api/maintenanceServiceType';
    public static GET_MAINTENANCE_SERVICE_TYPE: string = '/api/maintenanceServiceType';
    public static UPDATE_MAINTENANCE_SERVICE_TYPE: string = '/api/maintenanceServiceType';
    public static DELETE_MAINTENANCE_SERVICE_TYPE: string = '/api/maintenanceServiceType';

    //Reminder
    public static FETCH_REMINDERS: string = '/api/reminder';
    public static FILTER_REMINDERS = '/api/reminder/filter';
    public static ADD_REMINDER: string = '/api/reminder';
    public static GET_REMINDER: string = '/api/reminder';
    public static UPDATE_REMINDER: string = '/api/reminder';
    public static DELETE_REMINDER: string = '/api/reminder';

    //Maintenance Event
    public static FETCH_MAINTENANCE_EVENTS: string = '/api/maintenanceEvent';
    public static FILTER_MAINTENANCE_EVENTS = '/api/maintenanceEvent/filter';
    public static ADD_MAINTENANCE_EVENT: string = '/api/maintenanceEvent';
    public static GET_MAINTENANCE_EVENT: string = '/api/maintenanceEvent';
    public static UPDATE_MAINTENANCE_EVENT: string = '/api/maintenanceEvent';
    public static DELETE_MAINTENANCE_EVENT: string = '/api/maintenanceEvent';

    //Geo Location
    public static GET_GEO_LOCATION: string = '/api/geoLocation/closest-city-info';

    //Driver Daily Form
    public static FETCH_DRIVER_DAILY_FORMS: string = '/api/driverDailyForm/by-driver-times';
    public static GET_DRIVER_DAILY_FORM: string = '/api/driverDailyForm';
    public static ADD_DRIVER_DAILY_FORM_SECOND: string = '/api/driverDailyForm';
    public static UPDATE_DRIVER_DAILY_FORM_SECOND: string = '/api/driverDailyForm';
    public static GET_BY_DRIVER_DAILY_FORM: string = '/api/driverDailyForm/get-by';

    //Logs
    public static LOGS_MAIN: string = '/api/logs/main';

    //Edit apis
    public static FETCH_DAILY_SUMMARIES: string = '/api/client/edit-service/daily-summaries';
    public static DUPLICATE_ELD_EVENT: string = '/api/client/edit-service/duplicate';
    public static GET_BY_ID_ELD_EVENT: string = '/api/client/edit-service/get-eld-event';
    public static ADD_ELD_EVENT: string = '/api/client/edit-service/add-eld-event';
    public static UPDATE_ELD_EVENT: string = '/api/client/edit-service/update-eld-event';
    public static MOVE_EVENTS: string = '/api/client/edit-service/move-events';
    public static DELETE_ELD_EVENT: string = '/api/client/edit-service/delete-eld-event';
    public static DELETE_ELD_EVENTS: string = '/api/client/edit-service/delete-eld-events';
    public static FETCH_DRIVER_DAILY_FORM: string = '/api/client/edit-service/get-driver-daily-form';
    public static ADD_DRIVER_DAILY_FORM: string = 'api/client/edit-service/add-driver-daily-form';
    public static UPDATE_DRIVER_DAILY_FORM: string = 'api/client/edit-service/update-driver-daily-form';

    //deletion menu
    public static FETCH_DELETION_MENU: string = '/api/deletion-menu/providers';
    public static GET_DELETION_MENU: string = '/api/deletion-menu/carriers';
    public static ASSIGN_TEST_DRIVER: string = '/api/deletion-menu/assign-test-driver';

    //Route
    public static FETCH_DRIVER_STATUS: string = '/api/client/vehicle-state/latest-driver-status';
    public static FETCH_DAILY_EVENTS: string = '/api/client/vehicle-state/daily-events';

    // ###############################################################
    // events new
    public static GET_CURRENT_STATE_BY_DATE_NEW: string = '/api/client/driver-state-new/current-state';
    public static FETCH_LATEST_EVENTS: string = '/api/events/latest';
    public static FETCH_TAB_HISTORY_EVENTS: string = '/api/events/history';
    public static FETCH_BY_ID_ELD_EVENT: string = '/api/events';
    public static ADD_EVENT: string = '/api/events';
    public static DELETE_EVENT: string = '/api/events/delete';
    public static UPDATE_EVENT: string = '/api/events';
    public static COPY_EVENT: string = '/api/events/copy';
    public static HOS_EVENTS: string = '/api/events/new-hos';
    public static MOVE_EVENT: string = '/api/events/move';
    public static REVERT_EVENT: string = '/api/events/revert';
    public static ROLLBACK_EVENTS: string = '/api/events/rollback';
    public static SUBMIT_EVENT: string = '/api/events/submit';

    // daily summaries
    public static FETCH_DAILY_SUMMARIES_LATEST: string = '/api/event-summaries/latest-summaries';
    public static FETCH_DAILY_SUMMARIES_HISTORY: string = '/api/event-summaries/history-summaries';

    // driver profile forms
    public static FETCH_PROFILE_FORM_LATEST: string = '/api/driver-profile-forms/latest';
    public static FETCH_PROFILE_FORM_HISTORY: string = '/api/driver-profile-forms/history';
    public static FETCH_ONE_PROFILE_FORM: string = '/api/driver-profile-forms';
    public static ADD_PROFILE_FORM: string = '/api/driver-profile-forms/create';
    public static UPDATE_PROFILE_FORM: string = '/api/driver-profile-forms';

    // tab
    public static FETCH_TAB_INITIAL: string = '/api/tabs/init';
    public static POST_TAB: string = '/api/tabs/add';
    public static UPDATE_TAB: string = '/api/tabs';
    public static DELETE_TAB: string = '/api/tabs';
    public static GET_TAB: string = '/api/tabs';

    // sessions
    public static GET_SESSIONS: string = '/api/sessions/filter';
    public static GET_SESSION: string = '/api/sessions';
    public static UPDATE_SESSION: string = '/api/sessions';

    // deletion menu
    public static FETCH_PROVIDERS: string = '/api/deletion-menu/providers';
    public static FETCH_CARRIERS_BY_PROVIDER_ID: string = '/api/deletion-menu/carriers';
    public static ASSIGN_UPDATE_TEST_DRIVER: string = '/api/deletion-menu/assign-test-driver';

    // ifta
    // public static GET_IFTAS: string = '/api/sessions/filter';
    public static ADD_IFTA: string = '/api/ifta/generate';
    public static GET_IFTA: string = '/api/ifta/filter';

    // event processing
    public static ADD_EVENT_BY_OTHER: string = '/api/eld-event/save-by-other-user';
    public static GET_EVENT: string = '/api/eld-event';
}
